body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;*/
}

/* h1, h2 {
  font-family: 'Raleway';
  font-weight: 900;
} */

li::marker {
  color: #6a0be3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-html5-camera-photo video {
  width: auto !important;
  /* height: 260px; */
}

/* .react-html5-camera-photo {
  width: 550px;
  height: 320px;
} */

.react-html5-camera-photo video {
  width: 100% !important;
}

.rs-radio:hover,
.rs-radio-checked {
  border: 3px solid #6a0be3 !important;
}

.rs-radio-checker {
  width: 120px;
  height: 130px;
}

.rs-radio-checker > label {
  display: flex !important;
  white-space: normal !important;
  font-weight: 600 !important;
  color: #444444 !important;
  box-sizing: border-box;
  width: 120px;
  height: 130px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 3px #ddd;
}

.rs-radio-group {
  flex-direction: row;
  display: flex !important;
  flex-wrap: wrap;
}

.rs-steps-item-status-process
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  background: #6a0be3 !important;
  border-color: #6a0be3 !important;
}

.rs-steps-item-status-finish
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon)
  .rs-steps-item-icon::before {
  color: #17E34D !important;
}

.rs-steps-item-status-finish
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color: #17E34D !important;
}

.rs-steps-item-status-finish .rs-steps-item-tail,
.rs-steps-item-status-finish .rs-steps-item-title::after {
  border-color: #17E34D !important;
}

.rs-slider-handle {
  top: -160% !important;
}

.rs-slider-handle::before {
  border-color: #6a0be3 !important;
  background-color: #6a0be3 !important;
  width: 24px !important;
  height: 24px !important;
  margin-left: -12px !important;
}

.rs-slider-progress-bar {
  background-color: #6a0be3 !important;
}

.rs-slider-graduator > ul > li.rs-slider-pass::before {
  border-color: #6a0be3 !important;
}

.spinner {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 1000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 1000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 450px) {
  .kaLCUD {
    top: 45px !important;
    width: 100vw !important;
    z-index: 1000000000000;
  }
  .examVy {
    box-shadow: none !important;
  }
  .hmjaFG {
    padding: 10px 6px 10px 50px !important;
  }
}
